import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Container, Row, Col, Media } from "react-bootstrap"
import Layout from "../components/layout"
import { Facebook, Instagram } from "react-bootstrap-icons"

export default function Contact({ data }) {
  return (
    <Layout>
      <style>{`
        .media-body h5 {
          font-weight: 300;
        }
        .w-hours {
          width: 90px;
          display: inline-block;
        }
      `}</style>
      <SEO title="Contact us" img={`/img/slider1.jpg`} />

      <div style={{ marginTop: "100px", marginBottom: "50px" }}>
        <Container>
          <Row>
            <Col>
              <h3 className="section-title mb-5">Contact Us</h3>
            </Col>
          </Row>
          <Row>
            <Col md={8} className="mb-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3156.2043700871604!2d-122.4706167!3d37.7148805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f7c4a6ccf493b%3A0x60b0e28083e6493e!2s3%20Mil%20Wil%20Hair%20Salon%20%26%20Barber%20Shop!5e0!3m2!1sen!2sus!4v1626907470986!5m2!1sen!2sus"
                title="contact-map-2021"
                width="100%"
                height="620"
                style={{ border: "0px" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Col>
            <Col md={4}>
              <Media className="mb-4">
                <Media.Body>
                  <h5>ADDRESS</h5>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.google.com/maps/place/3+Mil+Wil+Hair+Salon+%26+Barber+Shop/@37.7148805,-122.4706167,17z/data=!4m5!3m4!1s0x808f7c4a6ccf493b:0x60b0e28083e6493e!8m2!3d37.7148805!4d-122.4706167"
                    >
                      4077 19th Ave, San Francisco, CA 94132
                    </a>
                  </p>
                </Media.Body>
              </Media>
              <Media className="mb-4">
                <Media.Body>
                  <h5>PHONE</h5>
                  <p>
                    <a href={`tel:${data.site.siteMetadata.phone}`}>
                      {data.site.siteMetadata.phone}
                    </a>
                  </p>
                </Media.Body>
              </Media>
              <Media className="mb-4">
                <Media.Body>
                  <h5>E-MAIL</h5>
                  <p>
                    <a href={`mailto:${data.site.siteMetadata.email}`}>
                      {data.site.siteMetadata.email}
                    </a>
                  </p>
                </Media.Body>
              </Media>
              <Media className="mb-4">
                <Media.Body>
                  <h5>HOURS</h5>
                  <p>
                    <span className="mr-1 w-hours">Monday</span> Closed
                    <br />
                    <span className="mr-1 w-hours">Tue - Sat:</span> 10:00 AM -
                    08:00 PM
                    <br />
                    <span className="mr-1 w-hours">Sunday</span> Closed
                  </p>
                </Media.Body>
              </Media>
              <Media className="mb-4">
                <Media.Body>
                  <h5>SOCIAL</h5>
                  <p>
                    <a
                      style={{ marginRight: "15px" }}
                      aria-label="Facebook"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/3-Mil-Wil-Hair-Salon-Barber-107129460990568/"
                    >
                      <Facebook />
                    </a>
                    <a
                      style={{ marginRight: "15px" }}
                      aria-label="Instagram"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/3milwilhairsalonandbarbershop/"
                    >
                      <Instagram />
                    </a>
                  </p>
                </Media.Body>
              </Media>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
        address
        phone
        email
      }
    }
  }
`
